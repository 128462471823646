html,
body {
  font-family: "Roboto", "Arial", sans-serif;
}

body {
  padding-top: 56px;
  padding-bottom: 3rem;
}

.jumbotron {
  h1.display-1 {
    font-size: 36pt;
    font-weight: 100;
  }
  h1.display-2 {
    font-size: 24pt;
    font-weight: 100;
  }
}

.navbar {
  .navbar-flag {
    max-height: 25px;
    max-width: 32px;
  }
}

footer {
  font-size: 8pt;
}
